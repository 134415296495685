<template>
  <v-tabs
    class="tabs"
    color="black"
    v-bind="tabsProps"
    @change="$emit('input',$event)"
  >

    <v-tab
      v-for="( item, index ) in computedItems"
      :key="item.key || index"
      v-bind="item.props"
    >
      {{ item.text }}
    </v-tab>

  </v-tabs>
</template>

<script>
import { VTabs } from 'vuetify/lib/components/VTabs';

export default {
  components: { VTabs },
  props: {
    ...VTabs.options.props,
    value: null,
    disabled: Boolean,
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    tabsProps() {
      const props = { ...this.$props };
      delete props.items;
      return props;
    },
    computedItems() {
      return this.items.filter( Boolean ).map(( item, index ) => {
        if ( typeof item !== 'object' ) item = { text: item };
        let { text, ...props } = item;
        if ( this.value !== index ) {
          props.disabled = props.disabled || !!this.disabled;
        }
        return { text, props };
      });
    }
  }
}
</script>

<style>
.tabs .v-tab {
  text-transform: none;
}
</style>
