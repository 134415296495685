var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{ref:"table",attrs:{"api":"users","filters":_vm.filters,"headers":_vm.headers,"dialog-props":_vm.dialogProps,"compute":_vm.compute,"edit":_vm.edit,"ignore-status":""},on:{"click:item":function($event){return _vm.edit( $event.item.item )}},scopedSlots:_vm._u([(!_vm.isAppUser)?{key:"header",fn:function(){return [_c('Tabs',{attrs:{"items":_vm.tabs,"height":"56"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}})]},proxy:true}:null,{key:"item.value.name",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-row',{staticClass:"d-inline-flex ma-0",attrs:{"align":"center"}},[(item.picture)?_c('d-image',{staticClass:"shrink mr-2",attrs:{"value":item.picture,"width":"30","height":"30","color":"transparent","item-image":"url","hide-placeholder":"","outlined":"","rounded":"","contain":""}}):_vm._e(),_vm._v(" "+_vm._s(value)+" "+_vm._s(item.surname || '')+" ")],1)]}},{key:"item.value.socials",fn:function(ref){
var value = ref.value;
return [(value)?_c('a',{attrs:{"href":value.link,"title":value.username,"target":"_blank"}},[_vm._v(" "+_vm._s(value.name)+" ("+_vm._s(value.followers)+") ")]):_vm._e()]}},{key:"item.value.status",fn:function(ref){
var _obj;

var value = ref.value;return [_c('span',{class:( _obj = {}, _obj[((value.color) + "--text")] = !!value.color, _obj )},[_vm._v(" "+_vm._s(value.text)+" ")])]}},{key:"footer",fn:function(){return [_c('v-spacer'),(_vm.isAppUser)?_c('btn',{staticClass:"mr-2",attrs:{"color":"primary","loading":_vm.exporting},on:{"click":function($event){$event.stopPropagation();return _vm.exportData.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('btn.export'))+" ")]):_vm._e(),_c('btn',{on:{"click":function($event){$event.stopPropagation();return _vm.edit()}}},[_vm._v(" "+_vm._s(_vm.$t('users.btn.new'))+" ")])]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }