<template>
  <table-view
    ref="table"
    api="users"
    :filters="filters"
    :headers="headers"
    :dialog-props="dialogProps"
    :compute="compute"
    :edit="edit"
    @click:item="edit( $event.item.item )"
    ignore-status
  >
  <template v-if="!isAppUser" v-slot:header>

    <Tabs
      v-model="tab"
      :items="tabs"
      height="56"
    />

  </template>
  <template v-slot:item.value.name="{ item, value }">
    <v-row class="d-inline-flex ma-0" align="center">

      <d-image
        v-if="item.picture"
        :value="item.picture"
        class="shrink mr-2"
        width="30" height="30"
        color="transparent"
        item-image="url"
        hide-placeholder
        outlined
        rounded
        contain
      />

      {{ value }} {{ item.surname || '' }}

    </v-row>
  </template>
  <template v-slot:item.value.socials="{ value }">

    <a
      v-if="value"
      :href="value.link"
      :title="value.username"
      target="_blank"
    >
      {{ value.name }} ({{ value.followers }})
    </a>

  </template>
  <template v-slot:item.value.status="{ value }">

    <span :class="{[`${value.color}--text`]: !!value.color }">
      {{ value.text }}
    </span>

  </template>
  <template v-slot:footer>

    <v-spacer/>

    <btn
      v-if="isAppUser"
      class="mr-2"
      color="primary"
      :loading="exporting"
      @click.stop="exportData"
    >
      {{ $t('btn.export') }}
    </btn>

    <btn @click.stop="edit()">
      {{ $t('users.btn.new') }}
    </btn>

  </template>
  </table-view>
</template>

<script>
import TableView from '@/components/TableView';
import DImage from '@/components/Image';
import Tabs from '@/components/Tabs';
import { ICONS, USER_ROLES, USER_STATUS, USER_STATUS_COLOR } from '@/utils/constants';
import { mapState } from 'vuex';

export default {
  name: 'Users',
  auth: true,
  head: vm => ({ title: vm.$i18n.t('users.title') }),
  layout: 'admin',
  layoutProps: ({ i18n }) => ({
    title: i18n.t('users.title'),
    icon: ICONS.users
  }),
  fetchData({ store, redirect }) {
    if (
      ! store.state.auth.isAdmin &&
      ! store.state.auth.isSuperAdmin
    ) {
      redirect('/');
    }
  },
  components: { TableView, Tabs, DImage },
  data: () => ({
    tab: 0,
    exporting: false
  }),
  computed: {
    ...mapState( 'app', [ 'isMobile' ]),
    isAppUser() {
      return this.$route.path.includes('app');
    },
    filters() {

      if ( this.isAppUser )
        return [{ field: 'role', intValue: USER_ROLES.USER }];

      return this.section >= 0 ?
        [{
          field: 'role',
          intValue: this.section
        }] : [{
          field: 'role',
          listValue: Object
            .values( USER_ROLES )
            .filter( rol => rol !== USER_ROLES.USER )
        }];
    },
    roles() {
      return Object.values( USER_ROLES ).slice(0,-1).map( v => ({
        value: Number(v),
        text: this.$t(`users.roles.${v}`)
      }));
    },
    status() {
      return Object.values( USER_STATUS ).map( v => ({
        value: Number(v),
        text: this.$t(`users.status.${v}`)
      }));
    },
    headers() {
      return [
        {
          key: 'name',
          text: this.$t('inputs.name.label'),
          mobile: true
        },
        {
          key: 'surname',
          text: this.$t('inputs.surname.label'),
          hide: true
        },
        {
          key: 'email',
          text: this.$t('inputs.email.label')
        },
        {
          key: 'phone',
          text: this.$t('inputs.phone.label')
        },
        {
          key: 'socials',
          text: this.$t('inputs.socials.label'),
          display: v => v && v[0] && v[0].social,
          hide: !this.isAppUser,
          sorteable: false,
          filter: false
        },
        {
          key: 'role',
          text: this.$t('inputs.role.label'),
          hide: this.section >= 0 || this.isAppUser,
          display: v => this.$t(`users.roles.${v}`),
          filter: false
        },
        {
          key: 'status',
          text: this.$t('inputs.status.label'),
          display: v => ({
            text: this.$t(`users.status.${v}`),
            color: USER_STATUS_COLOR[v]
          }),
          filter: {
            is: 'select-field',
            clearable: true,
            items: this.status
          }
        }
      ];
    },
    tabs() {
      return [{ value: -1, text: this.$t('users.roles.-1') }]
        .concat( this.roles )
        .map(( tab, i ) => ({
          ...tab,
          ripple: false,
          class: 'subtitle-2',
          disabled: i !== this.tab && this.$store.state.app.loading
        }));
    },
    section() {
      return this.tabs[this.tab].value;
    },
    dialogProps() {
      return {
        title: this.$t('users.removeTitle'),
        maxWidth: 500
      };
    }
  },
  methods: {
    compute( items ) {
      return ( items || [] ).map( item => {
        item.picture = item.picUrl ? { url: item.picUrl } : null;
        return item;
      });
    },
    edit( item ) {
      this.$router.push(
        this.$lp( this.$route.path + '/' + ( item ? item.id : 'new' ))
      );
    },
    exportData() {
      if ( this.exporting ) return;
      this.exporting = true;
      this.$refs.table.exportData().finally(() => {
        this.exporting = false;
      });
    }
  }
}
</script>
